.footerContainer {
    max-width: 80%;

    @media (max-width: 767px) {
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 90%;
    }
}

.copyrightText {
    font-family: 'LexendExaExtraLight';
    font-style: normal;
    font-weight: 200;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #747474;
}

.subscribeUsText {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.inputField {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 132%;
    color: #FFFFFF;
    background: transparent;
    border: none;
    padding: 0px;
    border-radius: 0;
    height: 40px;
}

::placeholder {
    color: #FFFFFF;
}

.footer-menu {
    font-family: 'LexendExaLight';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}

.social-media-icon {
    color: #FFFFFF;
    font-size: 24px;
    margin-bottom: 5px;
}

.btnSend {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #89FFF8;
}

.btnSend {
    display: flex;
    padding: 0.5rem;
    cursor: pointer;
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #89FFF8;
}

.btnSendText {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
    mix-blend-mode: normal;
    text-shadow: 0px 0px 5px #89FFF8;
}