.raceBg {
    background: url('../img/race/race_bg.jpg'), #000000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding-top: 7vh;

    @media (max-width: 991px) {
        padding-top: 10vh;
    }

    @media (max-width: 300px) {
        padding-top: 15vh;
    }
}

.tabBarBg {
    background: url('../img/race/bar_bg.png'), #000000;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

.tab-icon {
    margin-top: -10px;
}

.main-tab-title {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 0px;

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 0px 6px #89FFF8;
}

.nav-tabs-neutral {
    padding-left: 150px;

    @media (max-width: 991px) {
        padding-left: 0px;
    }
}

.tab-content-container {
    padding-left: 150px;

    @media (max-width: 991px) {
        padding-left: 0px;
    }
}

.race-content-title {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 50px;
    /* identical to box height, or 111% */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 0px 6px #89FFF8;
}

.table-dark {
    background-color: transparent;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #0071BC;
    border-width: 0 0 1px 0;
}

.race-content-comingsoon {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    /* identical to box height, or 0% */

    text-transform: uppercase;

    color: #FFFFFF;
}

.tableHeadText {
    font-family: 'LexendExaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 25px;
    /* identical to box height, or 250% */

    text-align: left;

    color: #FFFFFF;
}


.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link {
    cursor: pointer;
}

.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link.active {
    background-color: transparent !important;
    border: 2px solid #89FFF8;
    // box-shadow: 0px 0px 4px #89FFF8;
    border-width: 0 0 2px 0;
    border-radius: 0;
}