@font-face {
    font-family: 'LexendExaThin';
    src: url('../fonts/LexendExa-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'LexendExaExtraLight';
    src: url('../fonts/LexendExa-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'LexendExaLight';
    src: url('../fonts/LexendExa-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'LexendExaRegular';
    src: url('../fonts/LexendExa-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'LexendExaMedium';
    src: url('../fonts/LexendExa-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'LexendExaSemiBold';
    src: url('../fonts/LexendExa-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'LexendExaBold';
    src: url('../fonts/LexendExa-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'LexendExaExtraBold';
    src: url('../fonts/LexendExa-ExtraBold.ttf') format('truetype');
    font-weight:800;
    font-style: normal;
}

@font-face {
    font-family: 'LexendExaBlack';
    src: url('../fonts/LexendExa-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}