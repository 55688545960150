.gameExperience_bg {
    width: 100%;
    background: black;
    background-image: url('../img/gameexperience/header_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 10vh;
}

.subContainer {
    max-width: 90%;

    @media (max-width: 767px) {
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 90%;
    }
}

.lh-1 {
    line-height: 1;
}

.gameExperienceTitle {
    font-family: "Retake";
    font-size: 80px;
    font-weight: 500;
    color: white;
    text-shadow: 0px 0px 4px #89FFF8;
    text-transform: uppercase;

    @media (max-width: 300px) {
        font-size: 35px;
    }
}

.gameExperienceDesc {
    font-family: "Lexend";
    font-size: 16px;
    color: white;
}

.gameicon_img {
    width: 40%;
}

.gameicon_container {
    cursor: pointer;
    width: 20%;
    padding-top: 5%;
    padding-bottom: 5%;


    @media (min-width: 0px) {

        &:focus,
        &:hover,
        &:active {
            background: linear-gradient(180deg, rgba(17, 117, 156, 0.07227) 0%, rgba(84, 192, 188, 0.3) 100%);
            box-shadow: 0px 0px 50px rgba(15, 118, 156, 0.1953);
            backdrop-filter: blur(4px);
            border-radius: 31px;

            .hoverIcon {
                display: block;
            }
        }
    }
}

.gameicon1 {
    position: absolute;
    top: 20%;
    left: 5%;
}

.gameicon2 {
    position: absolute;
    top: 70%;
    left: 5%;
}

.gameicon3 {
    position: absolute;
    top: 85%;
    left: 45%;
}

.gameicon4 {
    position: absolute;
    top: 60%;
    right: 0%;
}

.gameicon5 {
    position: absolute;
    top: 30%;
    right: 0%;
}

.gameicon6 {
    position: absolute;
    top: -4%;
    right: 30%;
}

.hoverIcon {
    display: none;

    @media (max-width: 667px) {
        display: none;
    }
}

.gameIconHeader {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    font-size: 45px;

    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 0px 4px #89FFF8;

    @media (max-width: 767px) {
        font-size: 14px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 25px;
    }
}

.gameIconReadMore {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height, or 188% */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 767px) {
        font-size: 8px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 12px;
    }
}

.gameDetailsHeader {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 0px 4px #89FFF8;

    @media (max-width: 767px) {
        font-size: 25px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 40px;
    }
}

.gameDetailsDesc {
    font-family: 'LexendExaThin';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;

    @media (max-width: 767px) {
        font-size: 13px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 15px;
    }
}

.gamePopUpBg {
    background: linear-gradient(180deg, rgba(84, 192, 188, 0.5) 0%, rgba(17, 117, 156, 0.12045) 100%);
    box-shadow: 0px 0px 2px 3px rgba(137, 255, 248, 0.15), 0px 0px 50px rgba(15, 118, 156, 0.1953), inset 0px 0px 150px 5px #04151E;
    backdrop-filter: blur(50px);
    border-radius: 31px;
}

.closeBtn {
    cursor: pointer;
    font-size: 30px;
    top: 6%;
    right: 10%;
}

.floating {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(0px, 20px);
    }
}

.floating2 {
    animation-name: floating2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating2 {
    0% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(0px, 20px);
    }
}
