.teamBg{
    background: url('../img/team/teamBg.png'), #000000;
    background-position: center;
    background-size: cover;
    height: 100vh;

    @media screen and (min-width: 992px) {
        padding-top: 3vh;
        padding-left: 80px;
    }

    .logo{
        width: 20%;
        @media screen and (max-width: 676px) {
            width: 40%;
        }
    }
    
    .team-title{
        font-family: 'Retake';
        font-style: normal;
        font-weight: 400;
        font-size: 100px;
        line-height: 100px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        -webkit-text-stroke: 2px #89FFF8;
        text-shadow: 0px 0px 20px #000000;
        display: block;
        @media screen and (max-width: 676px) {
            font-size: 40px;
            line-height: 40px;
        }
    }
}