.aboutUs{
    background: #000000;
    min-height: 100vh;
    .section1{ //about
        background: url('../img/about/section1Bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        .section1_title{
            display: block;
            font-family: 'Retake';
            font-style: normal;
            font-weight: 400;
            font-size: 100px;
            line-height: 100px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-shadow: 0px 0px 10px #89FFF8;
            @media screen and (max-width: 676px) {
                font-size: 40px;
                line-height: 40px;
            }
        }
        .section1_desc{
            font-family: 'LexendExaLight';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 25px;
            color: #FFFFFF;
            text-transform: capitalize;
            @media screen and (max-width: 676px) {
                font-size: 14px;
                line-height: 23px;
            }
        }
    }
    .section2{ //vision mission
        padding-top: 4rem;

        .missionImg{
            width: 10%;
        }
        .section2_title{
            font-family: 'Retake';
            font-style: normal;
            font-weight: 400;
            font-size: 45px;
            line-height: 50px;
            text-transform: uppercase;
            color: #FFFFFF;
            @media screen and (max-width: 676px) {
                font-size: 30px;
                line-height: 35px;
            }
        }
        .section2_desc{
            font-family: 'LexendExaLight';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            color: #FFFFFF;
            display: block;
            text-transform: capitalize;
            @media screen and (max-width: 676px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }
    .intersection2and3{
        // background-image:  url('../img/about/intersectBg.png');
        background-image: linear-gradient(#020d1f 30%, rgba(0, 0, 0, 1) 100% ), url('../img/about/intersectBg.png');
        // background-size: cover;
        // height: 100%;
    }
    .middle{
        position: absolute;
        background: url('../img/about/Group 96.png'), #04101E;
        background-size: cover;
        background-position: center;
        top: -100px;
        left: 0px;
        height: 100%;
        z-index: -9;
    }

    .section3{ //inspiration
        .contentBg{
            background: linear-gradient(180deg, rgba(18, 103, 122, 0) 0%, rgba(18, 103, 122, 0.447917) 39.06%, #12677A 100%);
        }
        .corner{
            bottom: -5%;
            left: 10%;
        }
        .horse{
            bottom: -48%;
            right: -50%;
            text-align: right;
            .horseImg{
                transform: scale(0.55);
                z-index: -1;
            }
            @media screen and (max-width: 676px) {
                bottom: -10%;
                right: 0%;
                width: 60%;
                .horseImg{
                    transform: scale(1);
                    z-index: -1;
                }
            }

            @media screen and (min-width: 677px) and (max-width: 1024px) {
                bottom: -10%;
                right: 0%;
                width: 60%;
                .horseImg{
                    transform: scale(1);
                    z-index: -1;
                }
            }
        }
        .section3_title{
            font-family: 'Retake';
            font-style: normal;
            font-weight: 400;
            font-size: 80px;
            line-height: 150px;
            text-transform: uppercase;
            color: #FFFFFF;
            @media screen and (max-width: 676px) {
                font-size: 40px;
                line-height: 70px;
            }

            @media (max-width: 300px) {
                font-size: 30px;
            }
        }
        .section3_desc{
            font-family: 'LexendExaLight';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            color: #FFFFFF;
            display: block;
            @media screen and (max-width: 676px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }
    .section4{ //key highlight
        background: url('../img/about/section4Bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 5rem;
        .keyHighlight-Bg{
            bottom: -25vh;
            left: 0px;
            position: absolute;
            @media screen and (max-width: 676px) {
                bottom: 0vh;
                left: 0px;
            }
        }
        .section4_title{
            font-family: 'Retake';
            font-style: normal;
            font-weight: 400;
            font-size: 80px;
            line-height: 150px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-shadow: 0px 0px 4px #89FFF8;
            @media screen and (max-width: 676px) {
                font-size: 40px;
                line-height: 70px;
            }
        }
        .section4_desc{
            font-family: 'LexendExaLight';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            color: #FFFFFF;
            display: block;
            @media screen and (max-width: 676px) {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }
    .footer-section{
        background: url('../img/about/footerBg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .inspirationContainer {
        height: 100vh;

        @media screen and (max-width: 676px) {
            height: 80vh;
        }

        @media screen and (min-width: 677px) and (max-width: 1024px) {
            height: 80vh;
        }

        @media screen and (max-width: 300px) {
            height: auto;
        }
    }
}