#lgSideBar {
    .sideBar {
        display: none;

        @media (min-width: 992px) {
            display: block;
        }

    }

    .connectWalletDiv {
        position: absolute;
        top: 2vh;
        right: 1%;
    }

    .connectWalletBox {
        background: #89FFF8;
        border: 3px solid #FFFFFF;
        box-shadow: 0px 0px 18px 2px rgba(137, 255, 248, 0.5);
        border-radius: 25px;
        padding: 10px;
    }

    .connectWalletText {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;

        color: #000000;
    }

    .btn-toggle {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
    }

    .btn-close {
        position: absolute;
        top: 50%;
        right: 0;
    }

    .sideBarExpand {
        width: 40vw !important;
        background-color: rgba(45, 45, 45, 0.98) !important;
    }

    .sideBarCollapse {
        background-color: rgb(16, 16, 16) !important;
    }

    .pro-sidebar>.pro-sidebar-inner {
        background-color: transparent !important;
    }

    .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
        border: none;
    }

    .headerExpand {
        text-align: left;
    }

    .menuTitle {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;

        color: #6D6D6D;
    }

    .subNavBar {
        font-family: 'Retake';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        /* identical to box height, or 125% */

        text-transform: uppercase;

        color: #FFFFFF;
    }

    .navTextHover {
        cursor: pointer;
    }

    .navTextHover:hover {
        text-decoration: none;
    }

    .subNavBar2 {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        /* identical to box height */

        text-transform: uppercase;

        color: #FFFFFF;
    }
}


#smNavBar {
    display: block;

    @media (min-width: 992px) {
        display: none;
    }
}

.logo-sm {
    width: 70%;
}

.sidebar-collapse .navbar-collapse:before {
    @media screen and (max-width: 991px) {
        background: black !important;
        color: white !important;
    }
}

.sidebar-collapse .navbar .navbar-nav {
    @media screen and (max-width: 991px) {
        margin-top: 0 !important;
    }
}

.sm-subNavBar {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    /* identical to box height, or 125% */

    text-transform: uppercase;

    color: #FFFFFF;

    @media screen and (max-width: 300px) {
        font-size: 20px;
    }
}

.sm-subNavBar2 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height */

    text-transform: uppercase;

    color: #FFFFFF;
}

.connectWalletBox {
    background: #89FFF8;
    border: 3px solid #FFFFFF;
    box-shadow: 0px 0px 18px 2px rgba(137, 255, 248, 0.5);
    border-radius: 25px;
    padding: 10px;

    @media screen and (max-width: 300px) {
        padding: 5px;
    }
}

.connectWalletText {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    color: #000000;
}

.dropdownIcon {
    margin-top: -10px;
    font-size: 20px;
    padding-left: 10px;
}

.sidebar-collapse .navbar .dropdown.show .dropdown-menu {
    @media screen and (max-width: 991px) {
        height: 100%;
    }
}

.sidebar-collapse .navbar-collapse {
    @media screen and (max-width: 300px) {
        width: 200px;
    }
}

.nav-open .sidebar-collapse .navbar-translate {
    @media screen and (max-width: 300px) {
        transform: translate3d(-200px, 0, 0);
    }
}