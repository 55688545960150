.roadmap_bg {
    width: 100%;
    background: black;
    background-image: url('../img/roadmap/header_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media (max-width: 991px) {
        padding-top: 10vh;
    }
}

.sliderContainer {
    max-width: 90%;
    @media (max-width: 767px) {
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
    }
}

.roadmapHeaderTitle {
    font-family: "Retake";
    font-size: 80px;
    font-weight: 500;
    color: white;
    text-shadow: 0px 0px 4px #89FFF8;

    @media (max-width: 767px) {
        font-size: 50px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 50px;
    }

    @media (max-width: 300px) {
        font-size: 40px;
    }
}

.homeHeaderDesc {
    font-family: "Lexend";
    font-size: 16px;
    color: white;
}

.intro_bg {
    width: 100%;
    background: black;
    background-image: url('../img/home/intro_bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.homeEarnSubtitle {
    font-family: "Lexend";
    font-size: 20px;
    color: white;
}

.homeLiveSubtitle {
    font-family: "Lexend";
    font-size: 25px;
    color: white;
}

.collapseContent {
    background: linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, rgba(146, 255, 255, 0.2) 100.78%);
}

#roadmapSlider {
    max-width: 100%;

    .slide2 img {
        /* width: 15rem; */
        margin: 0rem;
        margin: 0 auto;
        transform: perspective(1000px) rotateY(25deg);
    }

    .slide2 {
        transform: scale(0.9);
        transition: transform 300ms;
        opacity: 1.0;
        text-align: center;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .slide3 img {
        /* width: 15rem; */
        margin: 0rem;
        margin: 0 auto;
        transform: perspective(1000px) rotateY(-25deg);
    }

    .slide3 {
        transform: scale(0.9);
        transition: transform 300ms;
        opacity: 1.0;
        text-align: center;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .activeSlide {
        transform: scale(1.0);
        opacity: 1;
        background-image: url('../img/roadmap/item_activeBg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .activeSlide img {
        transform: perspective(1000px) rotateY(0deg);
        opacity: 0;
    }

    .activeSlide .img-container {
        /* background-image: url(../img/map/map_glow.png); */
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 10px;
    }


    .roadmapContent {
        position: absolute;
        top: 5%;
        left: 0;
        right: 0;
        margin: auto;
        width: 75%;
        height: 100%;
        display: none;
        transition: 300ms;
        line-height: 15px;
    
        @media (min-width: 768px) and (max-width: 1024px) {
            line-height: 1.2;
        }
    }

    .activeSlide .roadmapContent
    {
        display: block;
        transition: 300ms;
    }
    

    .roadmap-phase {
        font-family: 'Retake';
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 80px;

        text-align: center;
        letter-spacing: -0.085em;
        text-transform: uppercase;

        color: #FFFFFF;

        text-shadow: 0px 0px 4px #89FFF8;

        @media (max-width: 767px) {
            font-size: 60px;
        }
    
        @media (min-width: 768px) and (max-width: 1024px) {
            line-height: 1.4;
            font-size: 40px;
        }
    }

    .roadmap-year {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height, or 150% */

        text-transform: uppercase;

        color: #FFFFFF;
        @media (max-width: 767px) {
            font-size: 20px;
        }
    
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 15px;
        }
    }

    .roadmap-details {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        /* or 125% */

        text-align: center;

        color: #89FFF8;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 15px;
        }
    }

    .arrow {
        background-color: #fff;
        position: absolute;
        cursor: pointer;
        z-index: 10;
    }

    .arrow svg {
        transition: color 300ms;
    }

    .arrow svg:hover {
        color: #68edff;
    }

    .next {
        right: -5%;
        top: 40%;
    }

    .prev {
        left: -5%;
        top: 40%;
    }

    .slick-dots {
        display: none;
    }
}