.drivetoearn_bg {
    width: 100%;
    background: black;
    background-image: url('../img/drive-to-earn/header_bg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 10vh;

    @media (max-width: 991px) {
        padding-top: 10vh;
    }
}

.hugeContainer {
    width: 180%;
    margin-top: -10%;

    @media (max-width: 767px) {
        width: 100%;
        margin-top: 0%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
        margin-top: 0%;
    }
}


.descBg {
    width: 100%;
    background-image: url('../img/drive-to-earn/desc-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.subContainer {
    max-width: 90%;

    @media (max-width: 767px) {
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 90%;
    }
}

.lh-1 {
    line-height: 1;
}


.driveHeaderTitle {
    font-family: "Retake";
    font-size: 100px;
    font-weight: 500;
    color: white;
    text-shadow: 0px 0px 4px #89FFF8;
    text-transform: uppercase;

    @media (max-width: 767px) {
        font-size: 50px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 50px;
    }
}

.driveHeaderDesc {
    font-family: "Lexend";
    font-size: 16px;
    color: white;
}