.header_bg {
    width: 100%;
    background: black;
    background-image: url('../img/home/header_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 3vh;
    padding-left: 80px;
    margin: 0;

    @media (max-width: 991px) {
        padding-top: 10vh;
        padding-left: 0px;
    }
}

.sectionPart {
    // min-height: 100vh;
}

.homeHeaderTitle {
    font-family: "Retake";
    font-size: 60px;
    font-weight: 500;
    color: white;
    text-shadow: 0px 0px 4px #89FFF8;

    @media (max-width: 767px) {
        font-size: 40px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 40px;
    }
}

.homeHeaderDesc {
    font-family: "Lexend";
    font-size: 16px;
    color: white;
}

.homeHeader-buttonText {
    font-family: "Lexend";
    font-size: 16px;
    color: white;
    text-shadow: 0px 0px 5px #89FFF8;
}

.headerButtonBox {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #89FFF8;
    padding: 15px;
}

.headerButtonBox2 {
    background: #00FFF0;
}

.intro_bg {
    width: 100%;
    background: black;
    background-image: url('../img/home/intro_bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.homeEarnSubtitle {
    font-family: "Lexend";
    font-size: 20px;
    color: white;
}

.homeLiveSubtitle {
    font-family: "Lexend";
    font-size: 25px;
    color: white;
}

.collapseContent {
    background: linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, rgba(146, 255, 255, 0.2) 100.78%);
}

.home-livestream {
    background: black;
    background-image: url('../img/home/livestream-bg.png');
    background-size: 140% 250%;
    background-position: center 40%;
    background-repeat: no-repeat;
}

#liveStreamSlider {
    max-width: 80%;

    @media (max-width: 767px) {
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 90%;
    }

    .slide2 img {
        /* width: 15rem; */
        margin: 0rem;
        margin: 0 auto;

    }

    .slide2 {
        transform: scale(0.7);
        transition: transform 300ms;
        opacity: 1.0;
        text-align: center;
        margin: 0 auto;

    }

    .activeSlide {
        transform: scale(1.0);
        opacity: 1;
    }

    .activeSlide .img-container {
        /* background-image: url(../img/map/map_glow.png); */
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 10px;
    }

    .glowEffect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 4px 4px 317px rgba(0, 0, 0, 0.7);
        filter: blur(5px);
        display: none;
    }

    .buttonGlowEffect {
        display: none;
    }

    .slider-EnterHover {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 180px;
        height: 85px;
        background: #89FFF8;
        z-index: 100;
    }

    .sliderContent {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slideNation {
        font-family: "Lexend";
        font-size: 16px;
        color: white;
        font-size: 500;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 13px;
        }
    }

    .slideTitle {
        font-family: "Lexend";
        font-size: 25px;
        color: #89FFF8;
        font-size: 700;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 17px;
        }
    }

    .slideClass {
        font-family: "Lexend";
        font-size: 15px;
        color: white;
        font-size: 500;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 13px;
        }
    }

    .slideGates {
        font-family: "Lexend";
        font-size: 20px;
        color: white;
        font-size: 600;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 11px;
        }
    }

    .slidePrice {
        font-family: "Lexend";
        font-size: 20px;
        color: #EBFF00;
        font-size: 600;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 11px;
        }
    }

    .slideCurrency {
        font-family: "Lexend";
        font-size: 16px;
        color: white;
        font-size: 500;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 11px;
        }
    }

    .slideEnterText {
        font-family: "Lexend";
        font-size: 20px;
        color: black;
        font-size: 600;
        @media (min-width: 768px) and (max-width: 1024px) {
            font-size: 16px;
        }
    }

    .slide-titleDiv {
        position: absolute;
        top: 20%;
    }

    .slide-bottomDiv {
        position: absolute;
        bottom: 10%;
    }

    .slide2:hover .glowEffect {
        display: block;
        transition: 500ms;
    }

    .slide2:hover .buttonGlowEffect {
        display: block;
        transition: 500ms;
    }


    .activeSlide #slideText {
        margin-top: 10px;
    }


    .arrow {
        background-color: #fff;
        position: absolute;
        cursor: pointer;
        z-index: 10;
    }

    .arrow svg {
        transition: color 300ms;
    }

    .arrow svg:hover {
        color: #68edff;
    }

    .next {
        right: 0%;
        top: 40%;
    }

    .prev {
        left: 0%;
        top: 40%;
    }

    .slick-dots {
        display: none;
    }
}


.home-horseSliderBG {
    background-image: url('../img/home/horse-slideBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.home-horseSlider {
    max-width: 90%;

    .slick-track {
        margin-top: 20vh;
        padding-bottom: 15vh;
    }

    @media (max-width: 767px) {
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 90%;
    }

    .blueBtn {
        color: #89FFF8;   
    }

    .circle {
        border: 2px solid #89FFF8;
        border-radius: 50%;
        padding: 10px;
    }

    .slide2 img {
        /* width: 15rem; */
        margin: 0rem;
        margin: 0 auto;

    }

    .slide2 {
        transform: scale(0.6);
        transition: transform 300ms;
        opacity: 1.0;
        text-align: center;
        margin: 0 auto;
    }

    .horseDetails {
        display: none;
    }

    .borderLine {
        margin-top: 60%;
    }

    .activeSlide {
        transform: scale(1.0);
        opacity: 1;
    }

    .activeSlide img {
        transform: scale(1.5);
        opacity: 1;

        @media (max-width: 960px) {
            transform: scale(1);
        }
    }

    .activeSlide .horseDetails {
        display: block;
    }

    .activeSlide .img-container {
        /* background-image: url(../img/map/map_glow.png); */
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 10px;
    }

    .glowEffect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 4px 4px 317px rgba(0, 0, 0, 0.7);
        filter: blur(5px);
        display: none;
    }

    .buttonGlowEffect {
        display: none;
    }

    .slider-EnterHover {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 180px;
        height: 85px;
        background: #89FFF8;
        z-index: 100;
    }

    .sliderContent {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slideHorseIndex {
        font-family: 'Retake';
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 80px;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px #89FFF8;
    }

    .slideHorseName {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: right;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .slideHorseDesc {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        text-align: right;
        color: #FFFFFF;
    }

    .slideTitle {
        font-family: "Lexend";
        font-size: 25px;
        color: #89FFF8;
        font-size: 700;
    }

    .slideClass {
        font-family: "Lexend";
        font-size: 15px;
        color: white;
        font-size: 500;
    }

    .slideGates {
        font-family: "Lexend";
        font-size: 20px;
        color: white;
        font-size: 600;
    }

    .slidePrice {
        font-family: "Lexend";
        font-size: 20px;
        color: #EBFF00;
        font-size: 600;
    }

    .slideCurrency {
        font-family: "Lexend";
        font-size: 16px;
        color: white;
        font-size: 500;
    }

    .slideEnterText {
        font-family: "Lexend";
        font-size: 20px;
        color: black;
        font-size: 600;
    }

    .slide-titleDiv {
        position: absolute;
        top: 30%;
    }

    .slide-bottomDiv {
        position: absolute;
        bottom: 10%;
        width: 80%;
    }

    .slide2:hover .glowEffect {
        display: block;
        transition: 500ms;
    }

    .slide2:hover .buttonGlowEffect {
        display: block;
        transition: 500ms;
    }


    .activeSlide #slideText {
        margin-top: 10px;
    }


    .arrow {
        background-color: #fff;
        position: absolute;
        cursor: pointer;
        z-index: 10;
    }

    .arrow svg {
        transition: color 300ms;
    }

    .arrow svg:hover {
        color: #68edff;
    }

    .next {
        right: 4%;
        top: 45vh;

        @media (max-width: 960px) {
            right: 5%;
            top: 52%;
        }   
        @media (max-width: 767px) {
            right: 4%;
            top: 68%;
        }
    
        // @media (min-width: 768px) and (max-width: 1024px) {
        //     font-size: 70px;
        // }
    
        // @media (max-width: 300px) {
        //     font-size: 2em;
        // }
    }

    .prev {
        right:10%;
        top: 45vh;

        @media (max-width: 960px) {
            right: 15%;
            top: 52%;
        }
        @media (max-width: 667px) {
            right: 30%;
            top: 68%;
        }
    }

    .slick-dots {
        display: none;
    }
}

.homeElementTitle {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 150px;
    text-align: center;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 0px 8px #89FFF8;

    @media (max-width: 767px) {
        font-size: 3.5em;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 70px;
    }

    @media (max-width: 300px) {
        font-size: 2em;
    }
}

.homeElementDesc {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
}

.home-hashBG {
    background-image: url('../img/home/hash_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.home-coinBG {
    background-image: url('../img/home/coin_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.homeHashTitle {
    font-family: 'Retake';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    text-align: center;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 0px 8px #89FFF8;

    @media (max-width: 767px) {
        font-size: 3.5em;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 70px;
    }

    @media (max-width: 300px) {
        font-size: 4em;
    }
}

.homeHashSubtitle {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: white;
}

.HashButtonBox {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #0C3F55;
    padding: 15px;
    width: 250px;
}

.hashButtonBox2 {
    background: #0F4A5C;
}

.reverse-column {
    @media only screen and (max-width: 767px) {
        flex-direction : column-reverse;
    }
}